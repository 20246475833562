@import "scss/variables/colors.scss"; @import "scss/variables/fonts.scss"; @import "scss/variables/media-queries.scss"; @import "scss/mixins/media-queries.scss"; @import "scss/mixins/styles.scss";
.component-fail {
  background: $color-neutrals-light-slate;
  display: grid;
  height: 100%;
  margin: 0;
  place-items: center center;
  width: 100%;
}

.message {
  color: $color-gray;
  font-family: $font-gt-america;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.18px;
  line-height: 20px;
  padding: 40px;
  text-align: center;
}

.message-icon {
  display: block;
  height: 54px;
  margin: 0 auto 10px;
  width: 54px;
}
