@import "scss/variables/colors.scss"; @import "scss/variables/fonts.scss"; @import "scss/variables/media-queries.scss"; @import "scss/mixins/media-queries.scss"; @import "scss/mixins/styles.scss";
@mixin max-size {
  height: 100%;
  width: 100%;
}

.image-container {
  @include max-size;
}

.lazy {
  @include max-size;
  object-fit: cover;

  @include tablet-size {
    object-fit: contain;
  }
}

.lazy-default,
.lazy-error {
  @include max-size;
  background-color: $color-neutrals-light-slate;
}

.lazy-cover {
  @include max-size;
  object-fit: cover;
}

.link {
  display: flex;
  height: 100%;
  width: 100%;
}
