@import "scss/variables/colors.scss"; @import "scss/variables/fonts.scss"; @import "scss/variables/media-queries.scss"; @import "scss/mixins/media-queries.scss"; @import "scss/mixins/styles.scss";
.bottom-zone {
  display: grid;
  margin: 56px auto 0;
  padding: 0 20px;
  position: relative;
  max-width: $mobile-max-width;

  @include tablet-size {
    column-gap: 40px;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 60px;
    max-width: none;
    padding: 40px 30px 0;
  }

  @include desktop-size {
    max-width: $desktop-max-width;
    padding: 40px 20px 0;
  }

  @include xl-desktop-size {
    margin-top: 80px;
    padding: 40px 0 0;

    &::after {
      width: calc(100%);
    }
  }

  &::after {
    @include tablet-size {
      background-color: $color-line-grey;
      content: "";
      height: 1px;
      left: 50%;
      position: absolute;
      top: 0;
      transform: translate(-50%);
      width: calc(100% - 40px);
    }

    @include xl-desktop-size {
      width: calc(100%);
    }
  }

  > div {
    position: relative;

    @include tablet-size {
      &::after {
        background-color: $color-line-grey;
        content: "";
        height: 100%;
        position: absolute;
        right: -20px;
        top: 0;
        width: 1px;
      }

      &:last-of-type {
        &::after {
          display: none;
        }
      }
    }
  }
}

.heading {
  color: $color-charcoal;
  font-family: $font-gt-america;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.3px;
  line-height: 21px;

  @include tablet-size {
    font-size: 17px;
    font-weight: 600;
    letter-spacing: -0.3px;
    line-height: 18px;
  }

  @include desktop-size {
    font-size: 17px;
  }
}
