.content-card {
  border-bottom: 1px solid $color-line-grey;
  column-gap: 24px;
  display: grid;
  font-family: $font-gt-america;
  grid-template-areas: "text image";
  grid-template-columns: auto 106px;
  padding: 20px 0;

  @include tablet-size {
    border-bottom: 0;
    display: block;
    position: relative;

    &:first-of-type {
      border-top: 0 !important;
    }

    // Adds custom styling to the previous card to the sponsored card
    &[data-element-position="prev"] {
      &::after {
        left: 0;
        width: 100%;
      }
    }

    // Adds custom styling to the next card to the sponsored card
    &[data-element-position="after"] {
      &::after {
        left: initial;
        width: 100%;
      }

      &::before {
        // Disable the left border
        display: none;
      }
    }

    &::before {
      background-color: $color-line-grey;
      content: "";
      height: 90%;
      left: -20px;
      position: absolute;
      width: 1px;
    }

    &::after {
      background-color: $color-line-grey;
      content: "";
      height: 1px;
      left: 0;
      position: absolute;
      top: 0;
      width: calc(100% + 40px);
    }

    &:first-child {
      padding-left: 0;

      &::before {
        display: none;
      }
    }

    &:last-child {
      padding-right: 0;

      &::after {
        width: 100%;
      }
    }
  }

  &:first-of-type {
    border-top: 1px solid $color-line-grey;
  }

  &:last-of-type {
    border-bottom: 0;
  }

  &:hover {
    cursor: default;

    [data-element-type="content-card-element-hover"] {
      &:not(:hover),
      &:not(:focus),
      &:not(:focus-within) {
        @include selected-link-opacity;
      }
    }
  }

  [data-element-type="content-card-element-hover"] {
    &:hover,
    &:focus,
    &:focus-within {
      @include selected-link;
    }
  }
}

.dek {
  align-self: center;
  grid-area: text;

  @include tablet-size {
    align-self: initial;
  }
}

.card-image {
  background: rgba($color-black, 0.05);
  border: 1px solid $color-line-grey;
  grid-area: image;
  height: 106px;

  @include tablet-size {
    height: min-content;
    margin-bottom: 13px;
  }

  @include desktop-size {
    margin-bottom: 15px;
  }
}

.head-feed {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.4px;
  line-height: 21px;
  margin: 8px 0;

  @include tablet-size {
    font-size: 17px;
    letter-spacing: -0.4px;
    line-height: 20px;
  }
}

.head-feed-link {
  color: $color-charcoal;
  text-decoration: none;
}

.slug {
  color: $color-aqua;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: -0.2px;
  line-height: 17px;
  margin: 8px 0;
  text-decoration: none;

  &:hover,
  &:focus,
  &:focus-within {
    @include selected-link;
  }
}
