@import "scss/variables/colors.scss"; @import "scss/variables/fonts.scss"; @import "scss/variables/media-queries.scss"; @import "scss/mixins/media-queries.scss"; @import "scss/mixins/styles.scss";
@import "../ContentCard";

.slug-link {
  color: $color-aqua;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: -0.2px;
  line-height: 17px;
  margin: 8px 0;
  text-decoration: none;

  &:hover {
    opacity: 0.6;
  }
}

.slug-dek {
  color: $color-aqua;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: -0.2px;
  line-height: 17px;
  margin: 8px 0;
  text-decoration: none;
}

.sponsor-container {
  border-bottom: 1px solid $color-line-grey;
  padding: 20px 0;

  @include tablet-size {
    border-bottom: 0;
    margin-left: -20px;
    padding: 0;
    width: calc(100% + 40px);
  }
}

.sponsor {
  background-color: $color-aqua-haze;
  border-radius: 2px;
  border-top: 0 !important;
  display: block !important;
  margin: 0 auto;
  max-width: 335px;
  padding: 30px 40px !important;

  @include tablet-size {
    padding: 20px !important;
  }

  &::after {
    // Disable the top border
    display: none;
  }
}

.sponsor-image {
  height: min-content;
  margin-bottom: 14px;
  margin-left: 0;
  width: 100%;

  @include tablet-size {
    margin-bottom: 11.8px;
  }

  @include desktop-size {
    margin-bottom: 16.97px;
  }
}

.sponsor-image-fixed {
  height: 140px !important;
  margin-bottom: 14px;

  @include tablet-size {
    margin-bottom: 8px;
  }
}

.sponsor-slug {
  display: none;
}
