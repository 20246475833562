@import "scss/variables/colors.scss"; @import "scss/variables/fonts.scss"; @import "scss/variables/media-queries.scss"; @import "scss/mixins/media-queries.scss"; @import "scss/mixins/styles.scss";
.newsletter-tout {
  background: $color-white;
  column-gap: 13px;
  display: grid;
  grid-template-columns: auto 106px;
  padding: 20px 0 0;

  @include tablet-size {
    column-gap: 2px;
    grid-template-columns: auto auto;
    margin-top: 0;
    padding: 0 0 0 20px;
  }

  @include desktop-size {
    column-gap: 3px;
    padding: 20px 0 0;
    width: auto;
  }
}

.description-text {
  color: $color-iron;
  font-family: $font-gt-america;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 21px;
  margin-bottom: 8px;
}

.sign-up-link {
  background: none;
  color: $color-charcoal;
  font-family: $font-gt-america;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  height: 20px;
  letter-spacing: -0.1px;
  line-height: 20px;
  text-align: left;
  text-decoration: none;

  svg {
    width: 16px;
  }

  &:hover,
  &:focus {
    cursor: pointer;
    @include selected-link;
  }
}

.newslettertout-icon {
  display: grid;
  justify-content: center;
}

.svg-icon {
  height: 66px;
  width: 55px;

  @include tablet-size {
    height: 49px;
    margin-top: -10px;
    width: 41px;
  }

  @include desktop-size {
    height: 49.46px;
    margin-top: -8px;
    width: 50px;
  }
}
