@import "scss/variables/colors.scss"; @import "scss/variables/fonts.scss"; @import "scss/variables/media-queries.scss"; @import "scss/mixins/media-queries.scss"; @import "scss/mixins/styles.scss";
.featured-content {
  padding: 0 0 20px;
  position: relative;

  &:hover {
    .topic:not(:hover) + .title {
      @include selected-link;
    }
  }

  @include tablet-size {
    padding: 0 20px 20px 30px;
  }

  @include desktop-size {
    padding: 0 20px 20px;
  }

  @include xl-desktop-size {
    padding: 0 20px 20px 0;
  }
}

.card-link {
  &:focus,
  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px;

    .title {
      @include selected-link;
    }
  }
}

.image-container {
  margin-bottom: 18.56px;

  @include tablet-size {
    margin-bottom: 16px;
  }

  @include desktop-size {
    margin-bottom: 15px;
  }
}

.featured-image {
  height: 100%;
}

.fixed-image {
  height: 100%;
  position: relative;
  width: 100%;
}

.article {
  margin: 0 auto;
  max-width: $mobile-max-width;

  @include tablet-size {
    margin: 0;
    max-width: none;
  }

  @include desktop-size {
    margin: 0 auto;
    max-width: 441px;
  }

  &::before {
    background-color: $color-line-grey;
    bottom: 0;
    content: "";
    height: 1px;
    left: 50%;
    margin: 0 auto;
    position: absolute;
    transform: translate(-50%);
    width: calc(100% - 40px);

    @include tablet-size {
      display: none;
    }
  }
}

.topic {
  color: $color-dark-green;
  display: block;
  font-family: $font-gt-america;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.2px;
  line-height: 18px;
  margin-bottom: 8px;
  padding: 0 20px;
  text-align: center;

  @include tablet-size {
    padding: 0;
  }

  &:hover,
  &:focus-within,
  &:focus {
    @include selected-link;
  }
}

.link {
  color: inherit;
  text-decoration: none;
}

.title {
  color: $color-iron;
  font-family: $font-tiempos-headline;
  font-size: 35px;
  font-style: normal;
  font-weight: 600; // Workaround until design fixes the figma font weight issue
  letter-spacing: -0.5px;
  line-height: 37px;
  margin-bottom: 3px;
  padding: 0 20px;
  text-align: center;

  @include tablet-size {
    font-size: 38px;
    letter-spacing: -0.3px;
    line-height: 41px;
    margin-bottom: 8px;
    padding: 0 40px;
  }

  @include desktop-size {
    padding: 0 16px;
  }
}

.dek {
  color: $color-gray;
  font-family: $font-gt-america;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.005px;
  line-height: 19px;
  margin-bottom: 0;
  padding: 0 40px;
  text-align: center;

  @include tablet-size {
    margin-bottom: 8px;
  }

  @include desktop-size {
    padding: 0 16px;
  }
}

.authors {
  color: $color-gray-092;
  display: none;
  font-family: $font-gt-america;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  padding: 0 20px;
  text-align: center;

  @include tablet-size {
    display: block;
    padding: 0;
  }
}
