@import "scss/variables/colors.scss"; @import "scss/variables/fonts.scss"; @import "scss/variables/media-queries.scss"; @import "scss/mixins/media-queries.scss"; @import "scss/mixins/styles.scss";
@import "../ContentCard";

.byline-link {
  display: none;
  text-decoration: none;

  @include tablet-size {
    color: $color-gray;
    display: block;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    margin: 8px 0;
  }
}
