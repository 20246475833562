@import "scss/variables/colors.scss"; @import "scss/variables/fonts.scss"; @import "scss/variables/media-queries.scss"; @import "scss/mixins/media-queries.scss"; @import "scss/mixins/styles.scss";
.for-you {
  margin: 0 auto;
  max-width: $mobile-max-width;
  padding: 20px 20px 0;

  @include tablet-size {
    border-left: 1px solid $color-line-grey;
    display: block;
    margin: 0;
    max-width: none;
    min-width: 245px;
    padding: 0 30px 20px 20px;
    position: relative;
  }

  @include desktop-size {
    border-right: 1px solid $color-line-grey;
    padding: 0 20px 20px;
  }

  &::before {
    background-color: $color-line-grey;
    content: "";
    height: 1px;
    left: 50%;
    margin: 0 auto;
    position: absolute;
    top: 0;
    transform: translate(-50%);
    width: calc(100% - 40px);

    @include tablet-size {
      display: none;
    }
  }
}

.title-icon {
  fill: none;
  margin-right: 8px !important;
}

.title {
  color: $color-charcoal;
  font-family: $font-gt-america;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.3px;
  line-height: 18px;
  margin-bottom: 11px;
  text-align: center;

  @include tablet-size {
    margin-bottom: 18px;
    text-align: left;
  }
}

.articles {
  border-bottom: 1px solid $color-line-grey;
  display: grid;

  @include tablet-size {
    border: 0;
  }

  @include desktop-size {
    grid-template-columns: auto;
  }
}
