@import "scss/variables/colors.scss"; @import "scss/variables/fonts.scss"; @import "scss/variables/media-queries.scss"; @import "scss/mixins/media-queries.scss"; @import "scss/mixins/styles.scss";
.link {
  background-color: $color-whisper-white;
  border: 1px solid $color-black;
  bottom: 0;
  display: inline-block;
  font: 12px "GT America";
  padding: 5px;
  position: fixed;
  right: 0;
  z-index: 1000000;

  &:hover {
    text-decoration: none;
  }
}
