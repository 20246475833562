@import "scss/variables/colors.scss"; @import "scss/variables/fonts.scss"; @import "scss/variables/media-queries.scss"; @import "scss/mixins/media-queries.scss"; @import "scss/mixins/styles.scss";
.popular-column {
  border-right: 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.popular-heading {
  border-bottom: 1px $color-line-grey solid;
  color: $color-charcoal;
  display: flex;
  font-family: $font-gt-america;
  font-size: 16px;
  font-weight: 600;
  justify-content: space-between;
  line-height: normal;
  letter-spacing: -0.3px;
  padding-bottom: 8px;

  @include tablet-size {
    display: block;
    padding-bottom: 16px;
  }

  @include desktop-size {
    display: flex;
    padding-bottom: 16px;
  }
}

.popular-heading-title {
  height: 21px;
  width: 72px;
  line-height: normal;

  @include tablet-size {
    font-weight: 600;
    line-height: 18px;
    height: 18px;
    width: 61px;
  }
}

.popular-link-container {
  border-bottom: 1px $color-line-grey solid;

  @include tablet-size {
    &:last-of-type {
      border-bottom: 0;
    }
  }
}

.popular-link {
  color: $color-iron;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font-family: $font-tiempos-headline;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  padding-bottom: 16px;
  padding-top: 16px;
  text-decoration: none;

  @include tablet-size {
    width: 160px;
  }

  @include desktop-size {
    width: 212px;
  }

  @include xl-desktop-size {
    width: 270px;
  }

  &:hover,
  &:focus {
    @include selected-link;
  }
}
