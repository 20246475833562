.content-box {
  display: block;
  flex-shrink: 0;
  font-family: $font-gt-america;
  padding: 20px;
  position: relative;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  text-align: left;
  transform: scale(1);
  transform-origin: center center;
  transition: transform 0.5s;
  width: calc($desktop-max-width / 4);

  @include tablet-size {
    padding: 20px 0;
    width: 100%;

    &:nth-of-type(4n + 1) {
      &::before {
        display: none;
      }
    }
  }

  // Adds custom styling to the previous card to the sponsored card
  &[data-element-position="prev"] {
    &::after {
      left: 0;
      // Crop the top border
      width: calc(100% - 20px);

      @include tablet-size {
        left: 0;
        // Crop the top border
        width: 100%;
      }
    }
  }

  // Adds custom styling to the next card to the sponsored card
  &[data-element-position="after"] {
    &::after {
      left: initial;
      // Crop the top border
      width: calc(100% - 20px);

      @include tablet-size {
        left: initial;
        // Crop the top border
        width: 100%;
      }
    }

    &::before {
      // Disable the left border
      display: none;
    }
  }

  &::before {
    background-color: $color-line-grey;
    content: "";
    height: 90%;
    left: 0;
    position: absolute;
    width: 1px;

    @include tablet-size {
      left: -20px;
    }
  }

  &::after {
    background-color: $color-line-grey;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    @include tablet-size {
      left: 0;
      width: calc(100% + 40px);
    }
  }

  &:first-child {
    &::after {
      left: 20px;
      width: calc(100% - 20px);

      @include tablet-size {
        left: 0;
        width: calc(100% + 40px);
      }
    }

    &::before {
      display: none;
    }
  }

  &:last-child {
    &::after {
      width: calc(100% - 20px);

      @include tablet-size {
        left: 0;
        width: 100%;
      }
    }
  }
}

.topic-image {
  border: 1px solid $color-line-grey;
  height: min-content;
  margin-bottom: 15px;

  @include tablet-size {
    margin-bottom: 9px;
  }

  &:hover {
    cursor: default;

    + .topics > [data-element-type="content-box-element-hover"] {
      &:not(:hover),
      &:not(:focus),
      &:not(:focus-within) {
        @include selected-link-opacity;
      }
    }

    ~ .dek > [data-element-type="content-box-element-hover"] {
      opacity: 0.6;
    }
  }

  + .topics > [data-element-type="content-box-element-hover"] {
    &:hover,
    &:focus,
    &:focus-within {
      @include selected-link;
    }
  }
}

.slug {
  display: inline-block;
  margin-bottom: 7px;
}

.slug-link {
  color: $color-dark-green;
  display: inline-block;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: -0.3px;
  line-height: 18px;
  text-decoration: none;

  @include desktop-size {
    letter-spacing: -0.3px;
    line-height: 17px;
  }

  &:hover,
  &:focus {
    @include selected-link;
  }
}

.sponsored-by-link {
  color: $color-pewter;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  position: relative;
  text-decoration: none;
}

.topic-title {
  color: $color-charcoal;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.15px;
  line-height: 20px;
  margin-bottom: 15px;
  text-decoration: none;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover,
  &:focus,
  &:focus-within {
    @include selected-link;
  }
}

.topic-title-link {
  color: $color-charcoal;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.15px;
  line-height: 20px;
  margin-bottom: 15px;
  text-decoration: none;
}
