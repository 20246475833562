@import "scss/variables/colors.scss"; @import "scss/variables/fonts.scss"; @import "scss/variables/media-queries.scss"; @import "scss/mixins/media-queries.scss"; @import "scss/mixins/styles.scss";
.hero-zone {
  display: grid;
  padding-top: 0;
  overflow: auto;

  @include tablet-size {
    grid-template-columns: 1.5fr 0.5fr;
    margin: 0 auto;
    padding-top: 41px;
  }

  @include desktop-size {
    grid-template-columns: 1fr 0.5fr 0.5fr;
    max-width: $desktop-max-width;
    padding-top: 84px;
  }
}
