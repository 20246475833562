@import "scss/variables/colors.scss"; @import "scss/variables/fonts.scss"; @import "scss/variables/media-queries.scss"; @import "scss/mixins/media-queries.scss"; @import "scss/mixins/styles.scss";
.newsletter-column {
  margin-top: 56px;

  @include tablet-size {
    margin-top: 0;
  }
}

.heading-container {
  align-items: center;
  border-bottom: 1px solid $color-line-grey;
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;

  @include tablet-size {
    display: block;
    padding-bottom: 16px;
  }

  @include xl-desktop-size {
    display: flex;
  }
}

.more-newsletters-btn {
  color: $color-gray;
  font-family: $font-gt-america;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 15px;
  text-decoration: none;
  display: inline-block;

  @include tablet-size {
    margin-top: 4px;
  }

  @include xl-desktop-size {
    margin-top: 0;
  }

  &:hover,
  &:focus {
    @include selected-link;
  }
}

.more-newsletters-icon {
  fill: $color-gray;
  height: auto;
  margin-left: 4px;
  width: 9px;
}

.newsletter {
  border-bottom: 1px solid $color-line-grey;
  column-gap: 14px;
  display: grid;
  grid-template-columns: 45px auto;
  padding: 16px 0;

  @include tablet-size {
    display: block;

    &:last-of-type {
      border-bottom: 0;
    }
  }

  @include desktop-size {
    display: grid;
  }
}

.newsletter-icon {
  height: auto;
  width: 45px;
}

.newsletter-icon-image {
  height: auto;
  width: 100%;
}

.newsletter-title {
  color: $color-charcoal;
  font-family: $font-gt-america;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.4px;
  line-height: 20px;
  margin-bottom: 4px;
}

.newsletter-dek {
  color: $color-gray;
  font-family: $font-gt-america;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 8px;

  @include tablet-size {
    margin-bottom: 16px;
  }
}

.newsletter-cta {
  color: $color-charcoal;
  font-family: $font-gt-america;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.1px;
  text-decoration: none;

  &:hover,
  &:focus {
    @include selected-link;
  }
}

.newsletter-cta-arrow {
  height: auto;
  width: 16px;
}
