@import "scss/variables/colors.scss"; @import "scss/variables/fonts.scss"; @import "scss/variables/media-queries.scss"; @import "scss/mixins/media-queries.scss"; @import "scss/mixins/styles.scss";
.link {
  padding: 5px;
  background-color: $color-whisper-white;
  border: 1px solid $color-black;
  top: 0;
  font: 12px "GT America";
  z-index: 1000000;
  text-decoration: none;
  left: -500px;
  width: fit-content;
  height: fit-content;

  &:focus,
  &:focus-visible,
  &:focus-within {
    opacity: 1;
    left: 0;
  }
}
