$color-white: #fff;
$color-whisper-white: #eee;
$color-gray: #666;
$color-gray-092: rgba(
  $color: $color-gray,
  $alpha: 0.92,
);
$color-line-grey: #e2e2e2;
$color-pewter: #787878;
$color-iron: #505050;
$color-dark-green: #075e64;
$color-dark-green-07: rgba(
  $color: $color-dark-green,
  $alpha: 0.07,
);
$color-charcoal: #282828;
$color-black: #000;
$color-aqua: #67aebd;
$color-aqua-haze: #ecf4f4;
$color-neutrals-light-slate: #ebedf0;

$color-line-grey-02: rgba(
  $color: $color-line-grey,
  $alpha: 0.2,
);
