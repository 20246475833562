@import "scss/variables/colors.scss"; @import "scss/variables/fonts.scss"; @import "scss/variables/media-queries.scss"; @import "scss/mixins/media-queries.scss"; @import "scss/mixins/styles.scss";
.topic-zone {
  margin: 0 auto;
  overflow: hidden;
  padding: 0;
  text-align: center;
  width: 100%;

  @include tablet-size {
    max-width: $desktop-max-width;
    padding: 0 30px;
  }

  @include desktop-size {
    padding: 0 20px;
  }

  @include xl-desktop-size {
    padding: 0;
  }

  &::-webkit-scrollbar {
    width: 0 !important;
  }
}

.wrapper {
  display: flex;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  scroll-snap-type: x both;

  @include tablet-size {
    column-gap: 40px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    max-width: $desktop-max-width;
    overflow: initial;
    row-gap: 20px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.slider {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 13px;

  @include tablet-size {
    display: none;
  }
}

.slider-button {
  background-color: $color-pewter;
  border-radius: 50%;
  display: inline-block;
  font-size: 0;
  height: 7px;
  margin: 0 2px;
  opacity: 40%;
  pointer-events: none;
  width: 7px;
}

.button-active {
  opacity: 100%;
}

.more-topics {
  color: $color-gray;
  display: inline-block;
  font-family: $font-gt-america;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 15px;
  margin-top: 24px;
  text-decoration: none;

  @include tablet-size {
    margin-top: 31px;
  }

  @include desktop-size {
    margin-top: 20px;
  }

  &:hover,
  &:focus {
    @include selected-link;
  }
}

.more-topics-icon {
  margin-left: 3px;
  opacity: 0.4;
}
