@mixin selected-link-opacity {
  opacity: 0.6;
}

@mixin selected-link {
  @include selected-link-opacity;
  text-decoration: underline;
}

@mixin subscriber-tout-header {
  font-family: $font-gt-america;
  font-size: 22px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 16px;

  @include tablet-size {
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 20px;
  }
}

@mixin subscriber-tout-cta {
  background-color: $color-gray;
  border-radius: 2px;
  color: $color-white;
  display: inline-block;
  font-family: $font-gt-america;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.171818px;
  line-height: 16px;
  padding: 7px 14px;
  text-align: center;
  text-decoration: none;
  width: 114px;

  &:hover {
    opacity: 0.6;
  }
}
