@import "scss/variables/colors.scss"; @import "scss/variables/fonts.scss"; @import "scss/variables/media-queries.scss"; @import "scss/mixins/media-queries.scss"; @import "scss/mixins/styles.scss";
.list-tile-heading-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 73px;
}

.heading {
  color: $color-charcoal;
  font-family: $font-gt-america;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.3px;
  text-align: center;
}

.heading-ellipses-icon {
  height: 4px;
  margin-bottom: 8px;
  width: 16px;
}

.list-tile-articles {
  @include tablet-size {
    max-height: 300px;
    min-height: 200px;
  }
}

.list-tile-heading-for-subscribers {
  color: $color-gray;
  text-align: center;
  font-family: $font-gt-america;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  margin-top: 4px;
  margin-bottom: 16px;
}

.article-thumbnail,
.error-article-thumbnail {
  background-color: $color-neutrals-light-slate;

  @include screen-min-width($mobile-max-width) {
    display: block;
    margin-left: auto;
    width: calc(100%);
  }
}

.article-heading-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include tablet-size {
    &::before {
      display: none;
    }
  }

  @include tablet-size {
    &:last-child {
      padding-bottom: 31px;
    }
  }

  &::before {
    background-color: $color-line-grey;
    bottom: 0;
    content: "";
    height: 1px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: calc(100%);
  }
}

.article-heading {
  border-bottom: 1px $color-line-grey solid;
  padding: 16px 0;
  text-align: center;
  max-width: 295px;

  @include screen-min-width($mobile-max-width) {
    max-width: 578px;
    padding: 24px 0;
  }

  @include tablet-size {
    border: 0;
    padding: 16px 0 0;
    width: 212px;
  }

  @include desktop-size {
    width: 281px;
  }

  @include xl-desktop-size {
    width: 332px;
  }
}

.article-heading-link {
  color: $color-charcoal;
  font-family: $font-gt-america;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.15px;
  text-decoration: none;

  @include tablet-size {
    font-size: 17px;
    letter-spacing: -0.4px;
    font-weight: 600;
  }

  &:hover,
  &:focus {
    @include selected-link;
  }
}

.cta-container {
  align-items: center;
  display: flex;
  width: 100%;
  margin-top: 16px;

  @include tablet-size {
    margin-top: 0;
    position: absolute;
    bottom: 0;
  }
}

.cta {
  color: $color-gray;
  font-family: $font-gt-america;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 15px;
  text-align: center;
  text-decoration: none;
  width: 100%;

  &:hover,
  &:focus {
    @include selected-link;
  }
}

.cta-arrow-icon {
  fill: $color-gray;
  opacity: 0.4;
  margin-left: 4px;
}
