@import "scss/variables/colors.scss"; @import "scss/variables/fonts.scss"; @import "scss/variables/media-queries.scss"; @import "scss/mixins/media-queries.scss"; @import "scss/mixins/styles.scss";
.podcasts-column {
  margin-top: 56px;

  @include tablet-size {
    margin-top: 0;
  }
}

.heading-container {
  align-items: center;
  border-bottom: 1px solid $color-line-grey;
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;

  @include tablet-size {
    display: block;
    padding-bottom: 16px;
  }

  @include xl-desktop-size {
    display: flex;
  }
}

.more-podcasts-btn {
  color: $color-gray;
  font-family: $font-gt-america;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 15px;
  text-decoration: none;

  @include tablet-size {
    display: inline-block;
    margin-top: 4px;
  }

  @include xl-desktop-size {
    margin-top: 0;
  }

  &:hover,
  &:focus {
    @include selected-link;
  }
}

.more-podcasts-icon {
  fill: $color-gray;
  height: auto;
  margin-left: 4px;
  width: 9px;
}

.podcast {
  border-bottom: 1px solid $color-line-grey;
  padding: 16px 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  @include tablet-size {
    display: block;
    justify-content: initial;

    &:last-of-type {
      border-bottom: 0;
      padding-bottom: 0;
    }
  }

  @include xl-desktop-size {
    display: grid;
    grid-template-columns: 45px auto;
    column-gap: 14px;
  }
}

.podcast-content {
  width: 80%;

  @include tablet-size {
    width: 100%;
  }
}

.podcast-topic {
  display: block;
  color: $color-gray;
  font-size: 15px;
  font-family: $font-gt-america;
  line-height: 17px;
  letter-spacing: -0.2px;
  margin-bottom: 4px;
}

.podcast-title {
  color: $color-charcoal;
  font-family: $font-gt-america;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.4px;
  line-height: 20px;
}

.podcast-title-link {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:focus {
    @include selected-link;
  }
}

.podcast-show-logo {
  width: 45px;
  height: auto;
  display: grid;
  align-items: center;
  margin-bottom: 8px;
  pointer-events: none;
}
