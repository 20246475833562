@import "scss/variables/colors.scss"; @import "scss/variables/fonts.scss"; @import "scss/variables/media-queries.scss"; @import "scss/mixins/media-queries.scss"; @import "scss/mixins/styles.scss";
.subs-onboarding-overlay-not-show {
  display: none;
}

.subs-onboarding-overlay {
  display: block !important;
  position: absolute;
  left: 0;
  background-color: $color-white;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9100;
  height: 100%;
  animation-name: opacityZero, fadeIn;
  animation-duration: 1500ms, 1000ms;
  animation-delay: 0ms, 1500ms;
  animation-timing-function: ease-in-out, ease-in-out;
  animation-iteration-count: 1, 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes opacityZero {
  from {
    opacity: 0;
  }

  to {
    opacity: 0;
  }
}
