@import "scss/variables/colors.scss"; @import "scss/variables/fonts.scss"; @import "scss/variables/media-queries.scss"; @import "scss/mixins/media-queries.scss"; @import "scss/mixins/styles.scss";
@import "../ContentBox";

.sponsored-by {
  color: $color-gray;
  font-size: 13px;
  letter-spacing: -0.005px;
  line-height: 16px;
  margin-top: 15px;
  padding: 2px 0;
}

.icon-sponsored-by {
  display: inline-block;
  margin-left: 3px;
  max-width: 90px;
}

.text-sponsored-by {
  white-space: normal;
}
