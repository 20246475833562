@import "scss/variables/colors.scss"; @import "scss/variables/fonts.scss"; @import "scss/variables/media-queries.scss"; @import "scss/mixins/media-queries.scss"; @import "scss/mixins/styles.scss";
.subscriber-tout {
  margin: 0 auto;
  max-width: $mobile-max-width;
  padding: 20px 0;
  position: relative;

  @include tablet-size {
    max-width: $desktop-max-width;
    padding: 20px;
  }

  @include xl-desktop-size {
    padding: 20px 0;
  }

  &::before {
    background-color: $color-line-grey;
    bottom: 0;
    content: "";
    height: 1px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: calc(100% - 40px);
  }
}

.container {
  background-color: $color-neutrals-light-slate;
  background-position: 30% 0%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 250px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 300px;

  @include tablet-size {
    height: 250.06px;
    max-width: 970px;
    width: 100%;
  }
}

.texts {
  left: 20px;
  position: absolute;
  top: 57px;
  width: 167px;

  @include tablet-size {
    left: 503px;
    top: 72px;
    width: 250px;
  }

  @include desktop-size {
    width: 400px;
  }
}

.logo {
  height: 30.56px;
  position: absolute;
  right: 22px;
  top: 21px;
  width: 55px;

  @include tablet-size {
    height: 65px;
    left: 36px;
    top: 75px;
    width: 116.18px;
  }
}

.header {
  @include subscriber-tout-header;
}

.header-white {
  @include subscriber-tout-header;
  color: $color-white;
}

.cta {
  @include subscriber-tout-cta;
}

.cta-white {
  @include subscriber-tout-cta;
  background-color: $color-charcoal;
}
