@import "scss/variables/colors.scss"; @import "scss/variables/fonts.scss"; @import "scss/variables/media-queries.scss"; @import "scss/mixins/media-queries.scss"; @import "scss/mixins/styles.scss";
@import "../ContentCard";

.media-slug-link {
  color: $color-dark-green;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 17px;
  margin: 8px 0;
  text-decoration: none;

  &:hover {
    opacity: 0.6;
  }
}
