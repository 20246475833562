@import "scss/variables/colors.scss"; @import "scss/variables/fonts.scss"; @import "scss/variables/media-queries.scss"; @import "scss/mixins/media-queries.scss"; @import "scss/mixins/styles.scss";
.hbr-specials-column {
  align-items: flex-start;
  border-right: 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-top: 56px;

  @include tablet-size {
    margin-top: 0;
  }
}

.article-container {
  width: 100%;
}

.content {
  border-bottom: 1px $color-line-grey solid;
  display: flex;
  flex-direction: row;
  padding: 16px 0;

  @include tablet-size {
    flex-direction: column-reverse;

    &:last-of-type {
      border-bottom: 0;
    }
  }

  @include xl-desktop-size {
    flex-direction: row-reverse;
  }
}

.heading {
  border-bottom: 1px $color-line-grey solid;
  display: block;
  padding-bottom: 8px;
  width: 100%;

  @include tablet-size {
    padding-bottom: 16px;
  }
}

.heading-subtitle {
  color: $color-gray;
  display: block;
  font-family: $font-gt-america;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  text-decoration: none;
  margin-top: 4px;
}

.text-container {
  display: flex;
  flex: 0 0 calc(100% - 106px);
  flex-direction: column;
  justify-content: center;
  padding-right: 16px;

  @include tablet-size {
    flex: 1;
    padding: 0;
  }

  @include xl-desktop-size {
    flex: 0 0 calc(100% - 100px);
    justify-content: flex-start;
    padding-left: 20px;
  }
}

.subtitle {
  color: $color-gray;
  font-family: $font-gt-america;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 17px;
  margin-bottom: 4px;

  @include tablet-size {
    margin-top: 8px;
  }

  @include desktop-size {
    padding: 0;
  }

  @include xl-desktop-size {
    margin-top: 0;
  }
}

.title {
  color: $color-charcoal;
  font-family: $font-gt-america;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.4px;
  line-height: 20px;
  padding-right: 20px;
  text-decoration: none;

  @include tablet-size {
    padding-right: 0;
  }

  &:hover,
  &:focus {
    @include selected-link;
  }
}

.image-container {
  flex: 0 0 106px;
  height: 106px;
  width: 106px;

  @include tablet-size {
    width: 100px;
    height: 100px;
    flex: 0 0 100px;
  }
}

.special-image {
  width: 100%;
  height: 100%;

  > a > img {
    object-fit: fill !important;
  }
}

.error-image {
  cursor: default;
  height: 100%;
  pointer-events: none;
  width: 100%;
}
