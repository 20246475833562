@import "scss/variables/colors.scss"; @import "scss/variables/fonts.scss"; @import "scss/variables/media-queries.scss"; @import "scss/mixins/media-queries.scss"; @import "scss/mixins/styles.scss";
.latest {
  margin: 0 auto;
  max-width: $mobile-max-width;
  padding: 20px 20px 0;

  @include tablet-size {
    grid-column: 1 / 4;
    grid-row: 2 / 3;
    margin: 0;
    margin-top: 40px;
    max-width: none;
    min-width: 100%;
    padding: 20px 30px;
    position: relative;
  }

  @include desktop-size {
    grid-column: auto;
    grid-row: auto;
    margin-top: 0;
    padding: 0 20px 20px;
  }

  @include xl-desktop-size {
    padding: 0 0 20px 20px;
  }

  &::before {
    @include tablet-size {
      background-color: $color-line-grey;
      content: "";
      height: 1px;
      left: 50%;
      margin: 0 auto;
      position: absolute;
      top: 0;
      transform: translate(-50%);
      width: calc(100% - 40px);
    }

    @include desktop-size {
      display: none;
    }
  }
}

.title {
  color: $color-charcoal;
  font-family: $font-gt-america;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.3px;
  line-height: 18px;
  margin-bottom: 16px;
  padding: 0;
  text-align: center;

  @include tablet-size {
    margin-bottom: 8px;
    text-align: left;
  }

  @include desktop-size {
    margin-bottom: 18px;
  }
}

.articles {
  display: grid;

  @include tablet-size {
    grid-template-columns: repeat(4, 1fr);
  }

  @include desktop-size {
    grid-template-columns: auto;
  }
}

.articles-fail {
  display: grid;

  @include tablet-size {
    grid-template-columns: 2fr 1fr;
  }

  @include desktop-size {
    grid-template-columns: auto;
    grid-template-rows: 2fr 1fr;
  }
}
