@import "scss/variables/colors.scss"; @import "scss/variables/fonts.scss"; @import "scss/variables/media-queries.scss"; @import "scss/mixins/media-queries.scss"; @import "scss/mixins/styles.scss";
.latest-zone {
  margin: 0 auto;
  margin-top: 35px;
  width: 100%;
  max-width: $mobile-max-width;
  padding: 0 20px;

  @include tablet-size {
    margin-top: 32.94px;
    max-width: none;
    padding: 0 30px;
  }

  @include desktop-size {
    margin-top: 30px;
    max-width: $desktop-max-width;
    padding: 0 20px;
  }

  @include xl-desktop-size {
    padding: 0;
  }
}
