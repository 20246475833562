@import "scss/variables/colors.scss"; @import "scss/variables/fonts.scss"; @import "scss/variables/media-queries.scss"; @import "scss/mixins/media-queries.scss"; @import "scss/mixins/styles.scss";
@mixin max-size {
  height: 100%;
  width: 100%;
}

.link {
  display: flex;
  height: 100%;
  width: 100%;
}

.error {
  @include max-size;
  background-color: $color-neutrals-light-slate;
}
