@import "scss/variables/colors.scss"; @import "scss/variables/fonts.scss"; @import "scss/variables/media-queries.scss"; @import "scss/mixins/media-queries.scss"; @import "scss/mixins/styles.scss";
.latest-row {
  @include tablet-size {
    column-gap: 40px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding-bottom: 20px;

    &:first-of-type {
      padding-bottom: 20px;
    }
  }

  @include desktop-size {
    padding-bottom: 50px;
  }

  &:last-of-type {
    border-bottom: 1px solid $color-line-grey;
  }
}
