@import "scss/variables/colors.scss"; @import "scss/variables/fonts.scss"; @import "scss/variables/media-queries.scss"; @import "scss/mixins/media-queries.scss"; @import "scss/mixins/styles.scss";
@import "../ContentBox";

.sponsor-container {
  background-color: $color-dark-green-07;
  border-bottom: 1px solid $color-line-grey;
  border-bottom: 0;

  @include tablet-size {
    border-bottom: 0;
    margin-left: -20px;
    padding: 0;
    padding-top: 24px;
    width: calc(100% + 40px);
  }
}

.sponsor {
  border-radius: 2px;
  border-top: 0 !important;

  @include tablet-size {
    padding: 20px !important;
  }

  &::after {
    // Disable the top border
    display: none;
  }
}

.sponsor-slug {
  display: none;
}

.sponsor-slug-link {
  color: $color-aqua;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: -0.2px;
  line-height: 17px;
}

.sponsor-slug-dek {
  color: $color-aqua;
  display: inline-block;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: -0.2px;
  line-height: 17px;
}

.sponsor-topic-title-link {
  color: $color-charcoal;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: -0.3px;
  line-height: 20px;
}

.sponsor-image {
  @include tablet-size {
    margin-bottom: 10px;
  }
}
