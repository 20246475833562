@import "scss/variables/colors.scss"; @import "scss/variables/fonts.scss"; @import "scss/variables/media-queries.scss"; @import "scss/mixins/media-queries.scss"; @import "scss/mixins/styles.scss";
.magazine-zone {
  display: grid;
  grid-template-areas:
    "header"
    "main"
    "articles"
    "nav";
  grid-template-columns: 1fr;
  margin: 0 auto;
  max-width: $mobile-max-width;
  padding: 20px;

  @include tablet-size {
    column-gap: 40px;
    grid-template-areas:
      "header header header"
      "nav    main    articles";
    grid-template-columns: 0.25fr 2fr 0.7fr;
    max-width: initial;
    position: relative;

    &::before {
      background: $color-line-grey;
      bottom: 0;
      content: "";
      height: 1px;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      width: calc(100% - 60px);
    }
  }

  @include desktop-size {
    max-width: $desktop-max-width;

    &::before {
      width: calc(100% - 40px);
    }
  }
}

.magazine-zone-fail {
  grid-template-areas: none;
  grid-template-columns: auto;
}

.header {
  display: flex;
  grid-area: header;
  justify-content: center;
  margin-bottom: 11px;
  margin-top: 8px;

  @include tablet-size {
    display: flex;
    justify-self: left;
    margin-bottom: 25px;
    margin-top: 8px;
    padding-left: 30px;
  }

  @include desktop-size {
    padding-left: 20px;
  }

  @include xl-desktop-size {
    padding-left: 0;
  }
}

.header-icon {
  margin-right: 8px;
}

.header-title {
  color: $color-charcoal;
  font-family: $font-stack-sans-serif;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: -0.3px;
  line-height: 18px;
  text-align: left;
}

.navigation {
  grid-area: nav;

  @include tablet-size {
    padding-left: 30px;
    position: relative;

    &::before {
      background: $color-line-grey;
      content: "";
      height: 100%;
      position: absolute;
      right: -20px;
      width: 1px;
    }
  }

  @include desktop-size {
    padding-left: 20px;
  }

  @include xl-desktop-size {
    padding-left: 0;
  }
}

.nav-cover {
  display: none;

  @include tablet-size {
    display: block;
    height: auto;
    margin-bottom: 21px;

    width: 86px;
  }

  @include desktop-size {
    margin-bottom: 28px;
    width: 104px;
  }
}

.nav-cover-fixed {
  @include tablet-size {
    height: 103px;
    margin-bottom: 21px;
    width: 86px;
  }

  @include desktop-size {
    height: 124px;
    margin-bottom: 28px;
    width: 104px;
  }
}

.current-issue {
  color: $color-gray;
  display: block;
  font-family: $font-gt-america;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 15px;
  margin-top: 20px;
  text-align: center;
  text-decoration: none;

  @include tablet-size {
    margin-top: 13px;
    text-align: left;
    width: 80%;

    &:first-of-type {
      margin-top: 0;
    }
  }

  @include desktop-size {
    margin-top: 19px;
    width: 100%;
  }

  &:hover,
  &:focus {
    @include selected-link;
  }
}

.archives {
  color: $color-gray;
  display: none;
  font-family: $font-gt-america;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 15px;
  margin-top: 20px;
  text-align: center;
  text-decoration: none;

  @include tablet-size {
    display: block;
    margin-top: 13px;
    text-align: left;
    width: 80%;

    &:first-of-type {
      margin-top: 0;
    }
  }

  @include desktop-size {
    margin-top: 19px;
    width: 100%;
  }

  &:hover,
  &:focus {
    @include selected-link;
  }
}

.icon {
  margin-left: 4px;
  opacity: 0.4;
}

.main-article {
  grid-area: main;

  @include tablet-size {
    position: relative;

    &::before {
      background: $color-line-grey;
      content: "";
      height: 100%;
      position: absolute;
      right: -20px;
      width: 1px;
    }
  }
}

.main-article-image {
  height: min-content;
  margin: 0 -20px;

  @include screen-min-width($mobile-max-width) {
    margin: 0;
  }
}

.fixed-image {
  background-color: $color-neutrals-light-slate;
  height: 215px;

  @include tablet-size {
    height: 257px;
  }

  @include desktop-size {
    height: 309px;
  }
}

.articles {
  grid-area: articles;
  margin: 0 auto;
  width: 100%;

  @include tablet-size {
    padding-right: 30px;
  }

  @include desktop-size {
    padding-right: 20px;
  }

  @include xl-desktop-size {
    padding-right: 0;
  }
}

.first-article-indicator {
  position: absolute;
  left: -22px;
  transform: rotate(90deg);
  fill: none;
  display: none;

  path {
    stroke: $color-iron;
    width: 10px;
  }

  @include tablet-size {
    display: block;
    top: -2px;
  }

  @include desktop-size {
    top: 2px;
  }
}

.article {
  border-bottom: 1px solid $color-line-grey;

  &:first-of-type {
    position: relative;
  }

  @include tablet-size {
    margin: 0;
    padding: 13px 0;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      border-bottom: 0;
    }
  }

  &:hover {
    [data-element-type="magazine-zone__article-element-hover"] {
      &:not(:hover),
      &:not(:focus) {
        @include selected-link-opacity;
      }
    }
  }

  [data-element-type="magazine-zone__article-element-hover"] {
    &:hover,
    &:focus {
      @include selected-link;
    }
  }
}

.article-title {
  color: $color-iron;
  font-family: $font-tiempos-headline;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
  padding: 15px 20px;
  text-align: center;

  @include tablet-size {
    font-size: 18px;
    font-weight: 600; // Workaround until design fixes the figma font weight issue
    letter-spacing: -0.2px;
    line-height: 22px;
    padding: 0;
    text-align: left;
  }

  @include desktop-size {
    font-size: 27px;
    letter-spacing: -0.4px;
    line-height: 30px;
    text-align: left;
  }
}

.article-authors {
  display: none;

  @include tablet-size {
    color: $color-gray-092;
    display: initial;
    font-family: $font-gt-america;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 16px;
    text-align: left;
  }
}

.article-link {
  color: inherit;
  text-decoration: none;
}
