@import "scss/variables/colors.scss"; @import "scss/variables/fonts.scss"; @import "scss/variables/media-queries.scss"; @import "scss/mixins/media-queries.scss"; @import "scss/mixins/styles.scss";
.content-row-container {
  display: grid;
  grid-template-columns: 1fr;
  margin: 48px auto;
  max-width: $mobile-max-width;
  padding: 0;
  row-gap: 48px;

  > div {
    overflow: hidden;
  }

  @include screen-min-width($mobile-max-width) {
    row-gap: 56px;
  }

  @include tablet-size {
    column-gap: 60px;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    margin: 42.85px auto 70px;
    max-width: none;
    padding: 0 30px;
    row-gap: 0;

    > :nth-child(3) {
      position: relative;
    }

    // Dividers between tiles.
    > :nth-child(-n + 2) {
      position: relative;

      &::after {
        background-color: $color-line-grey;
        content: "";
        height: 100%;
        position: absolute;
        right: -30px;
        top: 0;
        width: 1px;
      }
    }
  }

  @include desktop-size {
    margin: 50px auto;
    max-width: $desktop-max-width;
    padding: 0 20px;
  }
}
