@import "scss/variables/colors.scss"; @import "scss/variables/fonts.scss"; @import "scss/variables/media-queries.scss"; @import "scss/mixins/media-queries.scss"; @import "scss/mixins/styles.scss";
.heading-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 73px;
}

.ellipses-icon {
  height: 4px;
  margin-bottom: 8px;
  width: 16px;
}

.heading {
  color: $color-charcoal;
  font-family: $font-gt-america;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 18px;
  text-align: center;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
  width: 100%;
}

.heading-for-subscribers {
  color: $color-gray;
  font-family: $font-gt-america;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  margin-top: 4px;
  text-align: center;
  margin-bottom: 16px;
  text-align: center;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
  width: 100%;
}

.article-thumbnail,
.error-article-thumbnail {
  margin: 0;
  display: block;
  background-color: $color-neutrals-light-slate;
  pointer-events: none;

  @include tablet-size {
    margin-left: -20px;
    width: calc(100% + 40px);
  }

  @include screen-min-width($mobile-max-width) {
    margin-left: auto;
    width: calc(100%);
  }
}

.article-heading-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-height: 162px;
  overflow: hidden;
  position: relative;
  width: 100%;

  @include tablet-size {
    &::before {
      display: none;
    }
  }

  &::before {
    background-color: $color-line-grey;
    bottom: 0;
    content: "";
    height: 1px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: calc(100%);
  }
}

.article-heading {
  color: $color-charcoal;
  font-family: $font-gt-america;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.15px;
  line-height: 20px;
  margin-top: 16px;
  padding-bottom: 16px;
  position: relative;
  text-align: center;

  @include screen-min-width($mobile-max-width) {
    max-width: 578px;
    padding-bottom: 24px;
  }

  @include tablet-size {
    font-size: 17px;
    font-weight: 600;
    letter-spacing: -0.4px;
    padding-bottom: 0;
    margin-bottom: 4px;
    max-width: auto;

    &::before {
      display: none;
    }
  }

  @include desktop-size {
    line-height: 20px;
    margin-bottom: 8px;
  }
}

.article-dek {
  display: none;

  @include screen-min-width($mobile-max-width) {
    max-width: 578px;
  }

  @include tablet-size {
    display: block;
    color: $color-gray;
    text-align: center;
    font-family: $font-gt-america;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: -0.005px;
    max-width: auto;
  }

  @include desktop-size {
    letter-spacing: 0;
  }
}

.cta-container {
  align-items: center;
  display: flex;
  width: 100%;
  margin-top: 24px;

  @include screen-min-width($mobile-max-width) {
    margin-top: 16px;
  }

  @include tablet-size {
    margin-top: 0;
    position: absolute;
    bottom: 0;
  }
}

.cta {
  color: $color-gray;
  font-family: $font-gt-america;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 15px;
  text-align: center;
  text-decoration: none;
  width: 100%;

  &:hover,
  &:focus {
    @include selected-link;
  }
}

.cta-arrow-icon {
  fill: $color-gray;
  opacity: 0.4;
  margin-left: 4px;
}
